export const Parallaxtilt = () =>{
    class parallaxTiltEffect {

        constructor({element, tiltEffect}) {
      
          this.element = element;
          var targeted = document.getElementById("parallax");
          this.container = targeted;
          this.size = [300, 360];
          [this.w, this.h] = this.size;
      
          this.tiltEffect = tiltEffect;
      
          this.mouseOnComponent = false;
      
          this.handleMouseMove = this.handleMouseMove.bind(this);
          this.handleMouseEnter = this.handleMouseEnter.bind(this);
          this.handleMouseLeave = this.handleMouseLeave.bind(this);
          this.defaultStates = this.defaultStates.bind(this);
          this.setProperty = this.setProperty.bind(this);
          this.init = this.init.bind(this);
      
          this.init();
        }
      
        handleMouseMove(event) {
          const {offsetX, offsetY} = event;
      
          let X;
          let Y;
      
          if (this.tiltEffect === "reverse") {
            X = ((offsetX - (this.w/2)) / 3) / 6;
            Y = (-(offsetY - (this.h/2)) / 3) / 6;
          }
      
          else if (this.tiltEffect === "normal") {
            X = (-(offsetX - (this.w/2)) / 3) / 6;
            Y = ((offsetY - (this.h/2)) / 3) / 6;
          }
          
          this.setProperty('--rY', X.toFixed(3));
          this.setProperty('--rX', Y.toFixed(3));
      
          this.setProperty('--bY', (80 - (X/4).toFixed(3)) + '%');
          this.setProperty('--bX', (50 - (Y/4).toFixed(3)) + '%');
        }
      
        handleMouseEnter() {
          this.mouseOnComponent = true;
          this.container.classList.add("parallax--active");
        }
      
        handleMouseLeave() {
          this.mouseOnComponent = false;
          this.defaultStates();
        }
      
        defaultStates() {
          this.container.classList.remove("parallax--active");
          this.setProperty('--rY', 0);
          this.setProperty('--rX', 0);
          this.setProperty('--bY', '80%');
          this.setProperty('--bX', '50%');
        }
      
        setProperty(p, v) {
          return this.container.style.setProperty(p, v);
        }
      
        init() {
          this.element.addEventListener('mousemove', this.handleMouseMove);
          this.element.addEventListener('mouseenter', this.handleMouseEnter);
          this.element.addEventListener('mouseleave', this.handleMouseLeave);
        }
      
      }
      
      const $ = e => document.querySelector(e);
      
      const wrap3 = new parallaxTiltEffect({
        element: $('.wrap--3'),
        tiltEffect: 'reverse'
      });
      
}