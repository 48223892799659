import React, { Component } from "react"
import { Link } from "gatsby"
// import { Parallaxtilt } from "../components/tilter"
import Mypic from "../images/my-portrait-01.png"
import aboutMePic from "../images/about-me-pic.png"
import { Parallaxtilt } from "../components/tilter";
import { HeaderAnim } from "../components/animscripts/page-header-anim";

// var scrollTop = true;
export default class Home extends Component {
    constructor(props) {
        super(props)
        this.state={
            scrollPos : 0,
            scrollToggle: false,
        }
        // this.state = initialState;
        this.handleScroll = this.handleScroll.bind(this);
      }
      componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('mousemove', Parallaxtilt());
        HeaderAnim();
      }
      componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('mousemove', Parallaxtilt());
      }
      handleScroll = (e) =>{
          e.preventDefault();
        //   var aboutSection = document.getElementById('about');
          const winScroll = Math.round(document.body.scrollTop || document.documentElement.scrollTop);
            if(winScroll > 3){
                this.setState({
                    scrollToggle: true
                })
            }
            if(winScroll < 3){
                this.setState({
                    scrollToggle: false
                })
            }
            // console.log(winScroll, this.state.scrollToggle);

        }
        
    render(){
        const { scrollToggle }  = this.state;
        return(
            <div className="home__container">
                <section id="intro" className={ `home__intro ${this.state.scrollToggle ? 'scroll__deactivate' : 'scroll__activate'}` }>
                    <div className="home__intro__content__container">
                        <div className={`home__intro__mypic wrap--3 ${this.state.scrollToggle ? ' hide--hero--img' : 'show--hero--img'}`}>
                            <img id="parallax" className="parallax__tilt home__intro__mypic__img " src={Mypic} alt="My photo"/>
                            {/* <div className="home__intro__mypic__shape"></div> */}
                        </div>
                        <div className="home__intro__mypic__shape"></div>
                        <div className="home__intro__info">
                            <svg viewBox="0 0 70 30" className="home__intro__info__svg home__intro__info__svg__hello">
                                <text className={ `home__intro__info__svg__text ${this.state.scrollToggle ? ' ' : 'home--intro--1'}`} >Hello, I'm</text>
                            </svg>
                            <svg viewBox="0 0 80 30" className="home__intro__info__svg home__intro__info__svg__name">
                                <text className={ `home__intro__info__svg__text ${this.state.scrollToggle ? ' ' : 'home--intro--2'}`} >Riwat Rai</text>
                            </svg>
                            {/* <h2 className={`home__intro__info__text home__intro__info__text--title ${this.state.scrollToggle ? 'hide__hero__txt':'show__hero__text'}`}>Hello, I'm</h2>
                            <h2 className={`home__intro__info__text home__intro__info__text--name txt__stroke ${this.state.scrollToggle ? 'hide__hero__txt':'show__hero__text'}`}>Riwat Rai</h2> */}
                            <div className="home__intro__info__bottom" >
                                <p className={`home__intro__info__text home__intro__info__text--pos ${this.state.scrollToggle ? '' : 'show__subhero__text'}`}>a web developer on a mission to craft delightful online experiences. Let's collaborate and transform your ideas into visually stunning and functionally seamless websites.</p>
                                <Link to="/projects" className="home__intro__action__button home__intro__action__button--view cursor-hover">View my work</Link>
                            </div>
                        </div>
                    </div>
                    <div className="cross__wrapper cross__wrapper__home__bottom "></div>
                </section>
                <div className="scroll__icon">
                    {scrollToggle
                        ?<h6 className="scroll__icon__txt scroll__icon__txt__home">Home</h6>
                        :<h6 className="scroll__icon__txt scroll__icon__txt__about">About me</h6>
                    }
                    <div className="scroll__icon__mouse">
                        <div className={ `scroll__icon__scroller ${this.state.scrollToggle ? 'scroll--up' : 'scroll--down'}`}></div>
                    </div>
                </div>
                <div className={ `mobile__scroll__line ${this.state.scrollToggle ? 'mobile__scroll__line--reverse' : ''}` }></div>
                <section id="about" className={ `home__about ${this.state.scrollToggle ? 'scroll__activate' : 'scroll__deactivate'}` }>
                    <h3 className={ `home__about__header page__header__txt${this.state.scrollToggle ? '' : 'header--anim'}`}
                    >About<span> me</span></h3>
                    <div className="home__about__content">
                        <div className="home__about__content__media">
                            <img className={`home__about__content__img ${this.state.scrollToggle ? 'show__hero__img':'hide__hero__img'}`} src={aboutMePic} alt="my image 2"/>
                            <h3 className={`home__about__side__info home__about__side__info__designer ${this.state.scrollToggle ? 'slide--in--des--vert':''}`} >Designer</h3>
                            <h3 className={`home__about__side__info home__about__side__info__coder ${this.state.scrollToggle ? 'slide--in--horz':''}`} >Coder</h3>
                        </div>
                        <p className={ `home__about__content__info ${this.state.scrollToggle ? 'in--from--below' : ''}`}>
                        Ready to turn your digital vision into reality? I'm a self-taught Front-End Developer and UX/UI Designer based in the UK. Passionate about tackling complex challenges, I transform them into engaging, timeless solutions. Currently a Web Developer at Unsworth Sugden, I bring attention to detail and dedication to quality. Reach out via the contact form or email, and let's collaborate to bring your ideas to life!
                        </p>
                    </div>
                    
                </section >
            </div>
        )
    }
}
