import React from "react"
// import { graphql } from "gatsby" 
import '../styles/styles.scss'
import SEO from "../components/seo"
import Layout from "../components/layout"

import Home from "./home"

const IndexPage = () => (
  <React.StrictMode>
    <Layout>
      <SEO title="Riwat Rai's portfolio website"/>
      <Home></Home>
    </Layout>
  </React.StrictMode>
)



export default IndexPage
